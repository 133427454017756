
// export const environment = {
//     production: false,
//     baseHref: '/',
//     domain: 'https://th-hb-d-wa-api.azurewebsites.net/',
//     apiUrl: 'https://th-hb-d-wa-api.azurewebsites.net/api/',
//     imageUrl: "https://thhbdstg.blob.core.windows.net/crooms/properties/",    
// };

export const environment = {
    production: false,
    baseHref: '/',
    domain: 'https://admin.dev.kutralamrooms.in/',
    apiUrl: 'https://api.dev.kutralamrooms.in/api/',
    imageUrl: 'https://images.dev.kutralamrooms.in/',
};

// export const environment = {
//     production: false,
//     baseHref: '/',
//     domain: 'http://admin.jkbooking.test/',
//     apiUrl: 'http://admin.jkbooking.test/api/',
//     imageUrl: 'http://admin.jkbooking.test/storage/images/',
 
// };
